
    export default {
        name: 'ServicesBlock',
        computed: {
            servicesBlock() {
                return this.$store.state.servicesData;
            },
        },
        methods: {
            redirectToPage(slug, secondarySlug) {
                if (slug && secondarySlug) {
                    this.$router.push(`/${slug}/${secondarySlug}`);
                } else {
                    this.$router.push(`/${slug}`);
                }
            },
        },
    };
